@use "../../styles/breakpoints" as breakpoints;

.icon:active {
  border: 1px solid var(--secondary-red-color);
}

.icon {
  background: var(--primary-white-color);
  border: 1px solid var(--fifth-black-color);
  border-radius: 4px;
  margin-right: 8px;
}

.objectCard:hover .icon {
  background: var(--sixth-black-color);
}

@media screen and (max-width: breakpoints.$small) {
  .icon {
    height: 44px;
    width: 44px;
  }
}
