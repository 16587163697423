.companyDetails {
  width: 100%;
  background-color: var(--primary-white-color);
  position: relative;
  top: 0;
  z-index: 20;
  overflow: auto;
}
.typeFilterButton.disabled {
  color: var(--fourth-black-color);
  cursor: initial;
}
.typeFilterButton {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 1.555;
  padding: 10px 16px;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  color: var(--secondary-black-color);
  background: var(--primary-white-color);
  border: 1px solid var(--fourth-black-color);
  border-radius: 4px;
  cursor: pointer;
}

.forbiddenTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.0022em;
  color: var(--secondary-black-color);
  margin-bottom: 16px;
}

.forbiddenText {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 1.555;
  color: var(--secondary-black-color);
  margin-bottom: 32px;
}

.header {
  background-color: var(--sixth-black-color);
  padding: 32px 24px 24px;
  border-bottom: 1px solid var(--fifth-black-color);
}

.header .top {
  display: flex;
  justify-content: space-between;
  min-height: 32px;
  margin-bottom: 16px;
}

.closeBtn {
  width: 32px;
  height: 32px;
  background: var(--primary-white-color);
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.header .title {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0.0022em;
  color: var(--secondary-black-color);
  margin-bottom: 4px;
}

.header .description {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--third-black-color);
}

.contentNav {
  display: flex;
  align-items: center;
  padding: 12px 24px 0 24px;
  background-color: var(--sixth-black-color);
  border-bottom: 1px solid var(--fourth-black-color);
}

.contentTabLink {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--third-black-color);
  padding-bottom: 8px;
  cursor: pointer;
}

.active.contentTabLink {
  font-weight: 600;
  color: var(--secondary-black-color);
  border-bottom: 2px solid var(--secondary-black-color);
}

.contentTabLink:not(:last-child) {
  margin-right: 24px;
}

.detailsTab {
  padding: 32px 24px;
  margin-bottom: 96px;
  position: relative;
}
.objectCard {
  background-color: #f3f3f5;
  border-width: 1px;
  padding: 16px;
  margin-bottom: 16px;
}

.detailsTab::after {
  content: "";
  width: 91.5%;
  height: 1px;
  background-color: var(--fifth-black-color);
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.tabTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.444;
  color: var(--secondary-black-color);
  margin-bottom: 16px;
}

.tabTable {
  width: 100%;
  margin-bottom: 32px;
}
.tabObjectTable {
  width: 100%;
  margin-left: 12px;
  padding-right: 12px;
}
.boldP {
  font-weight: bold;
}
tr.tabRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.tabRow td {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 1.571;
  width: 244px;
  vertical-align: top;
  color: var(--secondary-black-color);
}
.tabObjectRow td {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 1.571;
  width: 224px;
  vertical-align: top;
  color: var(--secondary-black-color);
}

.tabRow:last-child {
  margin-bottom: 0;
}

.noRulesLink {
  position: relative;
  outline: none;
  background-color: transparent;
  border: none;
}

.noRulesLink:hover:after {
  content: attr(data-tooltip);
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  color: var(--primary-white-color);
  padding: 2px 4px;
  width: max-content;
  height: 22px;
  background: var(--secondary-black-color);
  border-radius: 2px;
  text-align: center;
  position: absolute;
  top: -35px;
}

.tabText {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 1.571;
  color: var(--secondary-black-color);
}

.contactSubTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
  margin-bottom: 4px;
}

.modalInfoWrapper {
  display: flex;
  flex-direction: row;
  padding: 12px 24px;
  height: 60px;
  background: #f0f6ff;
}

.modalInfoWrapper img {
  margin-right: 12px;
}

.infoText {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--secondary-black-color);
}

.bodyWrapper {
  padding: 24px;
  height: 108px;
  border-bottom: 1px solid var(--fifth-black-color);
}

.bodyText {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
}

.modalFooter {
  height: 80px;
  padding: 16px 24px;
}

.modalButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  padding: 0 24px 0 32px;
  width: 400px;
  height: 48px;
  border: 1px solid var(--secondary-black-color);
  color: var(--secondary-black-color);
  background-color: var(--primary-white-color);
  outline: none;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
}

.modalButton img {
  margin-left: 12px;
}
