@use "../../../../styles/breakpoints" as breakpoints;

.modalWrapper {
  width: 448px;
}

.modalHeader {
  width: 100%;
  height: 58px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--fifth-black-color);
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.444;
  letter-spacing: 0.002em;
  color: var(--primary-black-color);
}

.modalBody {
  width: 100%;
  height: 216px;
  padding: 24px;
  border-bottom: 1px solid var(--fifth-black-color);
}

.modalFooter {
  width: 100%;
  height: 72px;
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
}

.submitButton {
  width: 104px;
  height: 40px;
  background: var(--secondary-black-color);
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.285;
  text-align: center;
  letter-spacing: 0.0012em;
  color: var(--primary-white-color);
  border: none;
}

@media screen and (max-width: breakpoints.$small) {
  .modalWrapper {
    width: 343px;
  }
}
