@use "./styles/breakpoints" as breakpoints;
@use "./styles/zIndexes" as zIndexes;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");

/* Default scrollbar styles */

/* Restyle scrollbar for Windows */
@media (hover: hover) and (pointer: fine) {
  /* Apply custom scrollbar styles for non-mobile devices */
  *::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }

  *::-webkit-scrollbar-thumb {
    background-color: #e0e0e0; /* Color of the thumb */
    border-radius: 6px; /* Roundness of the thumb */
  }

  /* Hide scrollbar arrows in Chrome */
  *::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
}

/* Firefox scrollbar */
@-moz-document url-prefix() {
  * {
    scrollbar-width: thin; /* Width of the scrollbar */
    scrollbar-color: #e0e0e0 transparent; /* Color of the thumb and track */
    scrollbar-arrow-color: none; /* Hide scrollbar arrows */
  }
}

/* Touch devices detection */
@media (hover: none) and (pointer: coarse) {
  /* Apply default scrollbar styles for touch devices */
  body {
    scrollbar-width: auto; /* Supported in Firefox */
    scrollbar-color: auto; /* Supported in Firefox */
  }
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
}

//noinspection ALL
td {
  font-family: Roboto;
}

//noinspection ALL
button {
  font-family: Montserrat;
  &:disabled {
    cursor: not-allowed;
  }
}

//noinspection ALL
input {
  font-family: Roboto;
}

//noinspection ALL
input::placeholder {
  font-family: Roboto;
}

#root {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

button {
  cursor: pointer;
}

input {
  border: none;
  outline-width: 0;
}

th {
  font-weight: normal;
}

td {
  overflow-wrap: break-word;
}

:root {
  --secondary-font-family: Roboto;
  --headline-font-family: "Montserrat", sans-serif;
  --primary-white-color: #ffffff;
  --primary-black-color: #1d1e24;
  --secondary-black-color: #242731;
  --third-black-color: #575f6e;
  --fourth-black-color: #aeb1b5;
  --fifth-black-color: #e0e0e0;
  --sixth-black-color: #f5f5f7;
  --seventh-black-color: #fafbfc;
  --primary-red-color: #e04a3f;
  --secondary-red-color: #f46857;
  --third-red-color: linear-gradient(135deg, #f78e60 0%, #ed6145 100%);
  --fourth-red-color: linear-gradient(109.8deg, #f89e78 0%, #ef7860 100%);
  --fifth-red-color: linear-gradient(109.8deg, #fab090 0%, #f3917d 100%);
  --sixth-red-color: #fff4f3;
  --primary-blue-color: #225ed6;
  --secondary-blue-color: #3377ff;
  --third-blue-color: #4281ff;
  --fourth-blue-color: #75a3ff;
  --fifth-blue-color: #deeaff;
  --sixth-blue-color: #f5f7fa;
  --primary-green-color: #078f6d;
  --secondary-green-color: #0ead86;
  --third-green-color: linear-gradient(135deg, #1dc582 0%, #12b28a 100%);
  --fourth-green-color: linear-gradient(109.8deg, #42d187 0%, #36d9ad 100%);
  --fifth-green-color: #b7e3d1;
  --sixth-green-color: #e7f4ea;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  background-color: var(--primary-white-color);
}

/* mapbox */
.mapboxgl-popup {
  width: 350px;
  border: 1px solid var(--fifth-black-color);
  box-shadow: none;
  border-radius: 4px;
  top: -15px;
  cursor: pointer;
  max-width: none !important;
}

.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-content {
  width: 100%;
  background: transparent !important;
  padding: 0px !important;
  margin: 0px !important;
  box-shadow: none !important;
}

.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-canvas {
  width: 100%;
  height: auto;
}

.mapboxgl-ctrl-attrib-inner {
  display: none;
}
/* phone select */

.PhoneInputCountry {
  display: none !important;
}

.PhoneInputInput {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.333;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
}

.ant-message-notice-content {
  z-index: 13412312312312312312313;
}

.ant-breadcrumb-separator {
  margin: 2px 0 0 !important;
}

.ant-breadcrumb-separator img {
  display: block;
}

.ant-breadcrumb {
  @media (max-width: breakpoints.$small) {
    ol,
    li,
    a {
      height: 24px !important;
    }
    img {
      width: 20px;
    }
  }
}
.ant-breadcrumb a {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  font-style: normal;
  @media (max-width: breakpoints.$small) {
    color: var(--Dark-D300, #575f6e);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}
.ant-breadcrumb a:hover {
  background: var(--primary-white-color);
  color: var(--third-black-color);
}

.ant-form-item-label label {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  letter-spacing: 0.0012em !important;
  color: var(--secondary-black-color) !important;
}

.ant-select-item-option-selected {
  background: initial !important;
}

.ant-notification-notice {
  background: var(--primary-white-color) !important;
  border: 1px solid var(--sixth-black-color) !important;
  box-shadow: 0 8px 12px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31) !important;
  border-radius: 4px !important;
}

.ant-notification-notice-message {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 26px !important;
  align-items: center !important;
  letter-spacing: 0.002em !important;
  color: var(--secondary-black-color) !important;
}

.ant-notification-notice-description {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  display: flex !important;
  align-items: center !important;
  color: var(--secondary-black-color) !important;
}

.ant-notification-close-x {
  color: var(--third-black-color) !important;
}

.text-color-black-3 {
  color: var(--third-black-color) !important;
}
.text-color-black-4 {
  color: var(--fourth-black-color) !important;
}

//noinspection ALL
.ant-input,
.ant-input-password input {
  background: var(--primary-white-color) !important;
  border: 1px solid var(--fifth-black-color) !important;
  border-radius: 4px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 28px !important;
  height: 44px;
  box-shadow: none;
  font-family: "Roboto" !important;
}

.ant-input:hover,
.ant-select-selection:hover,
.ant-select-selector:hover {
  border: 1px solid var(--fourth-black-color) !important;
}

.ant-input:focus {
  border: 1px solid var(--secondary-black-color);
  outline: none !important;
  box-shadow: none;
}

.ant-input:active {
  outline: none !important;
  border: 1px solid var(--secondary-black-color) !important;
  box-shadow: none !important;
}

.ant-input[disabled] {
  background-color: var(--sixth-black-color) !important;
  color: var(--fourth-black-color) !important;
  border: 1px solid #e0e0e0;
}
.ant-input[disabled]:hover,
.ant-input[disabled]:active,
.ant-input[disabled]:focus {
  border: 1px solid var(--fifth-black-color) !important;
}

.ant-input-affix-wrapper-focused {
  border: 1px solid var(--secondary-black-color) !important;
  outline: none !important;
  box-shadow: none !important;
}

.ant-select-item-option-content {
  font-size: 18px;
}

.ant-input-affix-wrapper-focused .ant-input {
  border: none !important;
}
.ant-input-password .ant-input:hover {
  border: none !important;
}

.ant-select-selection,
.ant-select-selector {
  min-height: 44px !important;
}

.ant-select-selection:active,
.ant-select-selector:active,
.ant-select-selection:focus,
.ant-select-selector:focus {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid red;
}

.ant-select-selection:active,
.ant-select-selector:active {
  border: red;
}

.ant-select-focused .ant-select-selection,
.ant-select-focused .ant-select-selector {
  box-shadow: none !important;
  outline: none !important;
}

//noinspection ALL
.ant-select-selection-item {
  display: flex !important;
  align-items: center !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 28px !important;
}

.ant-input-password input {
  border: none !important;
}

.ant-input-password {
  padding-top: 0;
  padding-bottom: 0;
}

.full-width {
  width: 100% !important;
}

@media screen and (max-width: breakpoints.$small) {
  .mapboxgl-ctrl a.mapboxgl-ctrl-logo {
    display: none;
  }
  .mapboxgl-control-container .mapboxgl-ctrl-bottom-right {
    bottom: 60px;
  }
}
