.authButtonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.authButton {
  width: 160px;
  height: 50px;
  background: var(--fifth-black-color);
  border: 1px solid var(--fifth-black-color);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
