@use "../../styles/breakpoints" as breakpoints;

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ldsEllipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.ldsEllipsis div {
  position: absolute;
  top: 33px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--third-red-color);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.ldsEllipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.ldsEllipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.ldsRing {
  display: inline-block;
  position: relative;
  width: 15px;
  height: 15px;
}

.ldsRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: -6px;
  right: -5px;
  width: 15px;
  height: 15px;
  margin: 8px;
  border: 2px solid var(--third-black-color);
  border-radius: 50%;
  animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--third-black-color) transparent transparent transparent;
}

.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}

.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}

.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: breakpoints.$small) {
  .loader {
    height: 100%;
  }
}
