@use "../../../styles/breakpoints" as breakpoints;

.mainWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.mainWrapperColumn {
  width: 100%;
  flex: 0;
}

.infoWrapper {
  position: relative;
  padding: 16px 24px 12px 60px;
  max-width: 852px;
  min-height: 108px;
  background: var(--fifth-blue-color);
  border-radius: 4px;
  margin-bottom: 24px;
}

.infoWrapper img {
  position: absolute;
  top: 17px;
  left: 24px;
}

.infoWrapper p {
  font-family: var(--secondary-font-family);
  font-size: 16px;
  line-height: 1.5;
  color: var(--third-black-color);
  margin-bottom: 8px;
}

.infoWrapper a {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
}

.actualSubscribesWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  max-width: 852px;
  max-height: 610px;
  background: #fafafa;
  border: 1px solid var(--fifth-black-color);
  border-radius: 8px;
}

.subsTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.444;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
  margin-bottom: 16px;
}

.subWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 100%;
  background: var(--primary-white-color);
  border: 1px solid var(--fifth-black-color);
  border-radius: 4px;
}

.subHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 32px;
  width: 100%;
  margin-bottom: 24px;
  position: relative;
}

.subName {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.0022em;
  color: var(--secondary-black-color);
  margin-right: 24px;
}

.pausedSubInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 10px 2px 6px;
  width: max-content;
  min-height: 30px;
  background: var(--secondary-red-color);
  border-radius: 4px;
  margin-right: auto;
}

.pausedSubInfo p {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--primary-white-color);
  margin-left: 4px;
}

.winkingSubInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 10px 2px 6px;
  width: max-content;
  height: 30px;
  background: var(--sixth-green-color);
  border-radius: 4px;
  margin-right: auto;
}

.winkingSubInfo p {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--secondary-black-color);
  margin-left: 4px;
}

.showMoreButton {
  border: none;
  outline: none;
  background-color: transparent;
}

.display {
  display: none;
}

.menu {
  padding: 4px 0;
  position: absolute;
  width: max-content;
  height: auto;
  top: 35px;
  right: 10px;
  background: var(--primary-white-color);
  box-shadow: 0 8px 12px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
}

.menu button {
  padding: 8px 16px;
  width: 100%;
  height: 40px;
  font-family: var(--secondary-font-family);
  font-size: 16px;
  line-height: 1.5;
  color: var(--secondary-black-color);
  border: none;
  background-color: transparent;
}

.subBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-height: 68px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--fourth-black-color);
  gap: 16px;
}

.subCategory {
  align-items: flex-start;
  width: 220px;
  height: 50px;
}

.subCategoryName {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--third-black-color);
}

.subCategoryDesc {
  font-family: var(--secondary-font-family);
  font-size: 18px;
  line-height: 1.555;
  color: var(--secondary-black-color);
}

.subFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  width: 100%;
}

.planName {
  width: 215px;
  height: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
  background-color: transparent;
  border: none;
  text-align: start;
}

.planName span {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--secondary-red-color);
}

.userManagement {
  padding: 0 16px;
  width: 236px;
  min-height: 32px;
  background: var(--sixth-black-color);
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  text-align: center;
  letter-spacing: 0.0012em;
  color: var(--secondary-black-color);
  border: none;
  @media (max-width: breakpoints.$small) {
    padding: 8px 16px;
  }
}

.paymentContainer {
  padding: 24px;
  width: 384px;
  height: 186px;
  background: var(--primary-white-color);
  border: 1px solid var(--fifth-black-color);
  border-radius: 8px;
  margin-bottom: 24px;
}

.paymentContainer h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.444;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
  margin-bottom: 24px;
}

.paymentContainer p {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--third-black-color);
  margin-bottom: 16px;
}

.paymentContainer button {
  width: 205px;
  height: 24px;
  border: none;
  background-color: transparent;
  text-align: start;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--secondary-red-color);
}

.sumPayment {
  padding: 24px;
  width: 384px;
  height: auto;
  max-height: 350px;
  overflow-y: auto;
  background: var(--primary-white-color);
  border: 1px solid var(--fifth-black-color);
  border-radius: 8px;
}

.sumPaymentTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.444;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
  margin-bottom: 8px;
}

.subDate {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--third-black-color);
  margin-bottom: 32px;
}

.paymentPrices {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid var(--fifth-black-color);
}

.paymentPrices p {
  font-family: var(--secondary-font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: var(--secondary-black-color);
}

.paymentPrices span {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--third-black-color);
}

.sumResult {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sumResult p {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.444;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
}

@media screen and (max-width: breakpoints.$small) {
  .subBody {
    flex-wrap: wrap;
  }
  .sumPayment {
    width: 100%;
  }
  .mainWrapperColumn {
    flex: auto;
  }
  .secondaryWrapperColumn {
    width: 100%;
  }
  .subFooter {
    flex-wrap: wrap;
    gap: 16px;
  }
  .pausedSubInfo {
    margin-bottom: 16px;
    width: auto;
    max-width: 100%;
  }
}
