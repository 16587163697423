.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 500px;
  padding-top: 152px;
  margin: auto;
}

.close {
  position: absolute;
  top: 32px;
  right: 32px;
}

.mainTitle {
  font-weight: bold;
  font-size: 36px;
  line-height: 1.166;
  color: var(--primary-black-color);
  margin-bottom: 32px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 500px;
  height: 410px;
  background: var(--primary-white-color);
  border: 1px solid var(--fifth-black-color);
  border-radius: 8px;
  margin-bottom: 32px;
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--primary-black-color);
  margin-bottom: 32px;
  width: 100%;
}

.select {
  width: 100%;
  padding: 8px 8px 8px 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  display: block;
  appearance: none;
}

.select:active,
.select:focus {
  outline: none;
}

.select:hover {
  background-color: var(--sixth-black-color);
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.cancelButton {
  text-align: center;
  padding: 0 24px;
  width: max-content;
  height: 40px;
  border: none;
  background-color: var(--primary-white-color);
  font-weight: 600;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--primary-black-color);
  margin-right: 16px;
}

.submitButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0 24px;
  width: 224px;
  height: 40px;
  background: var(--secondary-black-color);
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--primary-white-color);
  border: none;
}

.errorMsg {
  margin-top: 10px;
  color: var(--primary-red-color);
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
}
