.logoWrap {
  width: 98px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-white-color);
  border: none;
  text-decoration: none;
}

/*noinspection ALL*/
.logoTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 0.75;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
  text-decoration: none !important;
}
