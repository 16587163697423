@use "../../styles/breakpoints" as breakpoints;

.inputWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 5px;
}

.errorMsg {
  color: var(--primary-red-color);
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
}

.customSelect {
  position: relative;
  cursor: pointer;
}

.closeIcon {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 2px;
  right: 30px;
  background-color: var(--seventh-black-color);
}

.closeIcon:hover {
  background-color: var(--fifth-black-color);
}

.displayNone {
  display: none;
}

.customArrow {
  position: absolute;
  top: 16px;
  right: 16px;
  display: block;
  background: var(--primary-white-color);
  height: 1px;
  width: 1px;
  pointer-events: none;
}

.customArrow:before,
.customArrow:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.customArrow:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--primary-black-color);
  top: 60%;
}

.checkboxInputHide {
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-width: calc(100% / 2);
  cursor: pointer;
  padding: 8px;
  margin-bottom: 8px;
}
.checkboxLabel:last-child {
  margin-bottom: 0;
}

.checkboxInput {
  background-repeat: no-repeat;
  cursor: pointer;
  @media screen and (max-width: breakpoints.$small){
    display: flex;
  }
}

.checkboxInput img {
  display: block;
}

.checkboxChildren {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: var(--secondary-black-color);
}

.radioLabel {
  cursor: pointer;
  align-items: flex-end;
    gap: 8px;
}

@media screen and (max-width: breakpoints.$small) {
  .checkboxLabel {
    margin-bottom: 0;
    width: 100%;
    flex-wrap: nowrap;
  }

  .customSelect {
    margin-bottom: 16px;
  }
  .customSelect:last-child {
    margin-bottom: 0;
  }
}
