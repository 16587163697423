@use "../../styles/breakpoints" as breakpoints;
@use "../../styles/zIndexes" as zIndexes;

.ddWrapper {
  position: relative;
}

.ddHeader {
  background: var(--primary-white-color);
  border: none;
  outline: none;
  width: 100%;
  text-align: left;
  // disabled
  color: var(--primary-black-color);
  &:disabled {
    color: var(--fourth-black-color);
  }
}

.ddHeaderMin {
  @media screen and (max-width: 400px) {
    width: min-content;
  }
}
.ddHeaderTitle {
  display: inline-block;
  font-family: var(--headline-font-family);
  font-size: 20px;
  font-weight: 600;
}

.ddList {
  background: var(--primary-white-color);
  width: 312px;
  font-family: var(--secondary-font-family);
  text-align: left;
  position: absolute;
  top: 40px;
  box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  padding: 8px 0;
}

.ddListItem {
  font-size: 16px;
  background: var(--primary-white-color);
  padding: 16px 10px 12px 10px;
  width: 100%;
  text-align: left;
  border: none;
  border-bottom: 1px solid var(--fifth-black-color);
  display: flex;
  justify-content: space-between;
  transition: background-color 0.3s ease;
  color: var(--primary-black-color);
}

.ddListItem:hover {
  background-color: var(--sixth-black-color);
}

.ddArrow {
  display: inline-block;
  vertical-align: top;
}

.ddArrow[data-disabled="true"] {
  opacity: 0.3;
}

@media screen and (max-width: breakpoints.$small) {
  .ddHeader {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .ddHeaderTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
    letter-spacing: 0.036px;
  }
}
