.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 680px;
  height: 840px;
  background: var(--primary-white-color);
  border-radius: 4px;
}

.header {
  width: 100%;
  padding: 16px 24px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--fifth-black-color);
}

.header p {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.444;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 100%;
  height: 712px;
  overflow-y: auto;
}

.body h1 {
  font-weight: bold;
  font-size: 36px;
  line-height: 1.166;
  color: var(--secondary-black-color);
  margin-bottom: 20px;
}

.body h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.444;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
  margin-bottom: 15px;
}

.body p {
  font-family: var(--secondary-font-family);
  font-size: 14px;
  line-height: 1.556;
  color: var(--third-black-color);
  margin-bottom: 10px;
}

.body ul li {
  font-family: var(--secondary-font-family);
  font-size: 14px;
  line-height: 1.556;
  color: var(--third-black-color);
  margin-bottom: 10px;
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 24px;
  height: 72px;
  border-top: 1px solid var(--fifth-black-color);
}

.footer button {
  width: 126px;
  height: 40px;
  text-align: center;
  background: var(--secondary-black-color);
  border-radius: 4px;
  border-color: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--primary-white-color);
}
