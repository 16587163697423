@use "../../../../../styles/breakpoints" as breakpoints;

.otherFiltersContainer {
  position: relative;
}

.filtersBody {
  padding: 24px;
  overflow: auto;
  height: 578px;
}

.documentationFilter {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e6e6;
}

.documentationFilter .stageDocWrapper {
  width: 100%;
}

.stageDocWrapper {
  width: 80%;
}

.documentationFilterContainer {
  position: relative;
}

.select {
  width: 100%;
  padding: 8px 8px 8px 16px;
  margin: 10px 0;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  display: block;
  appearance: none;
}

.select:active,
.select:focus {
  outline: none;
  border: 1px solid var(--secondary-black-color);
}

.select:hover {
  border: 1px solid var(--secondary-black-color);
  background-color: var(--sixth-black-color);
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 26px;
  margin-bottom: 25px;
}

.titleWrapper img {
  margin-left: 11px;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.0022em;
  color: var(--secondary-black-color);
}

.buildFilter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e6e6;
}

.constructionFilter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 90px;
}

.filterCheckboxWrapper {
  display: flex;
  align-items: center;
}

.filterCheckboxWrapper:not(:first-child) {
  flex-wrap: wrap;
}

@media screen and (max-width: breakpoints.$medium) {
  .filterBody {
    left: 0;
  }
}

@media screen and (max-height: breakpoints.$filter) {
  .filterBody {
    max-height: 400px;
  }
}

@media screen and (max-width: breakpoints.$small) {
  .filtersBody {
    padding: 0;
    overflow: visible;
  }
  .titleWrapper {
    margin-bottom: 24px;
  }
  .select {
    margin: 0;
    padding-right: 32px;
  }
  .documentationFilter {
    padding-bottom: 24px;
    margin-bottom: 24px;
  }
  .buildFilter {
    margin-bottom: 24px;
    padding-bottom: 24px;
  }
  .otherFiltersContainer {
    padding: 8px 0;
  }
  .stageDocWrapper {
    width: 100%;
  }
}
