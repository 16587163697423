@use "../../../../styles/breakpoints" as breakpoints;

.typeFilterButton {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  color: var(--secondary-black-color);
  background: var(--primary-white-color);
  border: 1px solid var(--fourth-black-color);
  border-radius: 4px;
  cursor: pointer;
}

.typeFilterButton.active {
  background: var(--sixth-black-color);
  border: 2px solid var(--secondary-black-color);
  padding: 9px 15px;
}

.typeFilterButton:hover {
  background: var(--sixth-black-color);
  border: 2px solid var(--secondary-black-color);
  padding: 9px 15px;
}

.overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(9, 30, 66, 0.06);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  z-index: 20;
}

.overlay.active {
  opacity: 1;
  pointer-events: all;
}

.applyBtns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 80px;
  padding: 16px 24px;
  border-top: 1px solid #e6e6e6;
}

.clearFiltersBtn {
  width: 170px;
  height: 48px;
  background-color: var(--primary-white-color);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
  border: none;
  margin-left: auto;
}

.clearAllFiltersBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  position: relative;
  left: 30%;
  font-weight: 600;
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
  background: var(--primary-white-color);
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: scale 0.3s ease-out;
}

.applyFiltersBtn {
  width: 170px;
  height: 48px;
  background: var(--secondary-black-color);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--primary-white-color);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filtersCount {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--primary-white-color);
  width: 19px;
  height: 18px;
  background: linear-gradient(109.8deg, #f78d61 0%, #ed6044 100%);
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.571;
  position: absolute;
  top: -7px;
  right: -7px;
}

.info {
  display: flex;
  align-items: center;
  height: 84px;
  padding: 24px;
  background: #f0f6ff;
}

.info img {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.info span {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--secondary-black-color);
}

.topContainerButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  width: 212px;
  height: 40px;
}

.topContainerTitleButton {
  display: flex;
  align-items: center;
  text-align: center;
  width: 160px;
  font-weight: 600;
  height: 40px;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  padding: 11px 24px 11px 18px;
  background: var(--sixth-black-color);
  border: 1px solid var(--fifth-black-color);
  border-radius: 4px;
  cursor: pointer;
}

@media screen and (max-width: breakpoints.$medium) {
  .typeFilterButton {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    padding: 4px 12px;
    border-radius: 4px;
    border: 1px solid var(--Dark-D400, #242731);
    white-space: nowrap;
  }
  .typeFilterButton.active {
    padding: 3px 11px;
  }

  .typeFilterButton:hover {
    padding: 3px 11px;
  }
}

@media screen and (max-width: breakpoints.$small) {
  .filtersCount {
    top: 0;
  }
  .info {
    height: auto;
  }
}
