.formGroup {
  max-height: 402px;
}

.dateFilterButton {
  width: 432px;
  height: 52px;
  padding: 12px 40px 12px 8px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 1.555;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--fifth-black-color);
  color: var(--secondary-black-color);
  background-color: var(--primary-white-color);
  text-align: left;
}

.filterOnMap {
  padding: 0;
  width: 100%;
}

.filterOnMap .datePickerWrapper {
  width: 75%;
}

.datePickerWrapperDisabled {
  pointer-events: none;
  opacity: 0.5;
}
