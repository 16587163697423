@use "../../../styles/breakpoints" as breakpoints;
@use "../../../styles/zIndexes" as zIndexes;

.nav {
  min-width: 100px;
  height: 100vh;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-white-color);
  box-shadow: inset -1px -1px 1px #d7d9db;
}

.top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.navList {
  text-align: center;
  list-style: none;
}

.navListItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.logo {
  padding-bottom: 26px;
  position: relative;
  margin: 0 auto 18px;
  text-decoration: none;
}

.logo::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 48px;
  height: 2px;
  background: #e6e6e6;
  border-radius: 18px;
}

.link {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 1.571;
  color: var(--secondary-black-color);
  text-decoration: none;
  padding-bottom: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--primary-white-color);
  position: relative;
}

.navListItem:hover .link {
  background: var(--third-red-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navListItem:hover svg path {
  fill: #f78d61;
}

.link.active svg path {
  fill: #f78d61;
}

.link .active {
  background: var(--third-red-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  cursor: pointer;
}

.badge {
  position: absolute;
  top: -15px;
  left: -27px;
  width: 37px;
  height: 18px;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
  color: var(--primary-white-color);
  background: var(--fourth-black-color);
  border-radius: 0 4px 4px 0;
}

.active .badge {
  background: linear-gradient(90deg, #fb8261 0%, #f46a57 100%);
}

.badge::before {
  content: "";
  position: absolute;
  left: 0;
  width: 3px;
  height: 70px;
  background: var(--fourth-black-color);
  z-index: 20;
}

.active .badge::before {
  background: linear-gradient(90deg, #fb8261 0%, #f46a57 100%);
}

//noinspection ALL
.link .text {
  margin-top: 10px;
  color: var(--dark-d-400, #242731);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  @media (max-width: 768px) {
    color: var(--Dark-D400, #242731);
    text-align: center;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 140% */
  }
}

.link .textActive {
  color: var(--secondary-red-color) !important;
}

.chatBox {
  width: 48px;
  height: 48px;
  background: var(--sixth-blue-color);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: breakpoints.$small) {
  .top {
    flex-direction: row;
    height: 100%;
    justify-content: space-around;
  }
  //noinspection ALL
  .nav {
    width: 100%;
    padding: 8px 0;
    overflow-x: auto;
    overflow-y: hidden;
    border-top: 1px solid var(--fifth-black-color);
    flex-shrink: 0;
    height: 60px;
    display: block;
    box-shadow: none;
    z-index: zIndexes.$navbar;
  }
  .link {
    min-width: 75px;
    flex: 0 1 auto;
    padding: 0;
  }
  .link .text {
    font-size: 10px;
    font-weight: 500;
    line-height: 14px; /* 140% */
    margin-top: 4px;
  }
  .link svg {
    min-height: 24px;
    min-width: 24px;
  }
  .logo {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    min-width: 75px;
    justify-content: center;
  }
  .logo::after {
    display: none;
  }
  .badge {
    display: none;
  }
  .link.menuBtn {
    margin-left: auto;
  }
}
