@use "../../styles/breakpoints" as breakpoints;

.calendarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
}

.customDatePickerWrapper {
  position: relative;
  width: 208px;
}

.calendarLabel {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--secondary-black-color);
}

.CalendarIcon {
  position: absolute;
  top: 40px;
  right: 10px;
}

.react-datepicker-wrapper {
  width: 208px;
  height: 44px;
  border: 1px solid var(--third-black-color);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 45px 10px 10px;
  margin-top: 8px;
}

.react-datepicker-wrapper:active,
.react-datepicker-wrapper:hover,
.react-datepicker-wrapper:focus {
  border: 1px solid var(--secondary-red-color);
}

/* Customisation for calendar */

.react-datepicker {
  position: relative;
  box-shadow: 0 8px 12px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
}

.react-datepicker__navigation {
  display: none;
}

.react-datepicker__day--disabled,
.react-datepicker__day--disabled:hover {
  color: var(--fourth-black-color) !important;
  background-color: var(--primary-white-color) !important;
  cursor: not-allowed;
}
.react-datepicker__header {
  background-color: var(--primary-white-color);
  border-bottom: none;
  padding: 8px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  display: none;
}

.react-datepicker__day-names {
  margin-bottom: -12px;
}

@media (max-width: breakpoints.$small) {
  .react-datepicker__month-container * {
    font-size: 18px;
  }
  .react-datepicker__year-select,
  .react-datepicker__month-select {
    color: var(--secondary-black-color) !important;
    font-size: 18px !important;
    background: transparent;
    padding: 0px 4px;
  }

  .react-datepicker__year-select option {
    font-size: 18px !important;
    color: var(--secondary-black-color) !important;
    background-color: transparent !important;
  }
  .react-datepicker__input-container {
    input {
      font-size: 18px;
      font-family: "Roboto";
      font-weight: "400";
      line-height: 28px;
      word-wrap: "break-word";
      width: inherit;
      color: var(--secondary-black-color);
    }
    input:focus {
      border: none;
      outline: none;
    }
    input::placeholder {
      color: var(--fourth-black-color);
      font-size: 18px;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      line-height: 28px;
      // word-wrap: break-word;
    }
  }
}
.react-datepicker__day-name,
.react-datepicker__time-name {
  width: 32px;
  height: 28px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.555;
  color: var(--secondary-black-color);
  margin: 14px;
}

.react-datepicker__day {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.555;
  color: var(--secondary-black-color);
  margin: 0;
  width: 60px;
  height: 57px;
  padding-top: 15px;
}

@media screen and (max-height: breakpoints.$datePicker) {
  .react-datepicker__day {
    width: 50px;
    height: 48px;
    padding-top: 12px;
  }

  .react-datepicker__day-name,
  .react-datepicker__time-name {
    width: 22px;
    height: 10px;
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  color: var(--secondary-red-color);
  background: var(--sixth-black-color);
  border-radius: 0;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  color: var(--secondary-red-color);
  background: var(--sixth-black-color);
  border-radius: 0;
}

.react-datepicker__day:hover,
.react-datepicker__day:active {
  color: var(--secondary-red-color);
  background: var(--sixth-black-color);
  border-radius: 0;
}

.react-datepicker__header__dropdown {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row-reverse;
  padding-bottom: 16px;
  border-bottom: 1px solid #e6e6e6;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  border: none;
  outline: none;
}

.react-datepicker__month-dropdown-container--select,
.react-datepicker__year-dropdown-container--select {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: var(--secondary-black-color);
  margin: 8px;
  padding: 1px;
  border: 1px solid var(--fourth-black-color);
  border-radius: 4px;
}

.react-datepicker__month-dropdown {
  left: 0;
}

.react-datepicker__close-icon::after {
  background-color: var(--secondary-black-color);
}

/* Customisation for month picker and calendar */

.monthPicker {
  position: absolute;
  top: 16px;
  right: 68px;
  width: 79px;
  height: 32px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 1.571;
  text-align: center;
  outline: none;
  color: var(--secondary-black-color);
  border: 1px solid var(--secondary-black-color);
  background: var(--primary-white-color);
  border-radius: 4px 0 0 4px;
  box-shadow: inset -1px 0px 0px #aeb1b5, inset 0px -1px 0px #aeb1b5,
    inset 0px 1px 0px #aeb1b5;
}

.monthPicker:active,
.monthPicker:hover {
  color: var(--secondary-red-color);
  border: 1px solid var(--secondary-red-color);
}

.monthPickCalendar {
  position: absolute;
  top: 70px;
  right: 0;
  width: 100%;
}

.react-datepicker__month .react-datepicker__month-text {
  width: 135px;
  height: 85px;
  padding-top: 35px;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  color: var(--secondary-red-color);
  background: var(--sixth-black-color);
  border-radius: 0;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border: 1px solid var(--secondary-red-color);
  color: var(--secondary-red-color);
  background: var(--sixth-black-color);
  border-radius: 0;
}

/* Customisation for year picker and calendar */

.yearPicker {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 52px;
  height: 32px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 1.571;
  text-align: center;
  outline: none;
  color: var(--secondary-black-color);
  border: 1px solid var(--secondary-black-color);
  border-left: none;
  background: var(--primary-white-color);
  border-radius: 0 4px 4px 0;
  box-shadow: inset -1px 0 0 #aeb1b5, inset 0 -1px 0 #aeb1b5,
    inset 0 1px 0 #aeb1b5;
}

.yearPicker:active,
.yearPicker:hover {
  color: var(--secondary-red-color);
  border: 1px solid var(--secondary-red-color);
}

.yearPickCalendar {
  position: absolute;
  top: 70px;
  right: 0;
}

.react-datepicker__year-wrapper {
  max-width: 100%;
  height: 354px;
}

.react-datepicker__year .react-datepicker__year-text {
  width: 135px;
  height: 85px;
  padding-top: 35px;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  border: 1px solid var(--secondary-red-color);
  color: var(--secondary-red-color);
  background: var(--sixth-black-color);
  border-radius: 0;
}

@media screen and (max-width: breakpoints.$small) {
  .calendarContainer {
    flex-direction: column;
    gap: 16px;
  }
  .customDatePickerWrapper {
    width: 100%;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    padding: 4px 12px;
    margin: 0;
    height: 59.333px;
    width: calc((100%) / 7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-datepicker__month {
    margin: 0;
    padding: 0 16px 16px;
  }
  .react-datepicker-popper {
    left: 0 !important;
    right: 0 !important;
  }
  .react-datepicker {
    width: 100%;
  }
  .react-datepicker__month-container {
    width: 100%;
  }
  .react-datepicker__header {
    padding: 16px 16px 0;
  }
  .react-datepicker__month-dropdown-container--select,
  .react-datepicker__year-dropdown-container--select {
    margin: 0;
    height: 32px;
  }
  .react-datepicker__year-dropdown-container--select {
    margin-right: 8px;
  }
  .react-datepicker__month-select,
  .react-datepicker__year-select {
    height: 100%;
  }
  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    margin: 0;
  }
  .react-datepicker.yearPickCalendar,
  .react-datepicker.monthPickCalendar {
    box-shadow: none;
    border: none;
  }
  .react-datepicker__year--container {
    height: 100%;
    margin: 0;
  }
  .react-datepicker__year {
    margin: 0;
  }
  .react-datepicker__year-wrapper {
  }
  .react-datepicker__year .react-datepicker__year-text,
  .react-datepicker__month .react-datepicker__month-text {
    width: calc(100% / 3);
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-datepicker__month.react-datepicker__monthPicker {
    padding: 0;
  }
  .react-datepicker__month-wrapper {
    display: flex;
  }
}
