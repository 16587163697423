@use "../../../styles/breakpoints" as breakpoints;

.header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  text-align: right;
  padding: 32px;
}

.regTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  color: var(--third-black-color);
}

.regLink {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.333;
  letter-spacing: 0.002em;
  color: var(--secondary-red-color);
  text-decoration: none;
}

.formWrapper {
  max-width: 500px;
  min-height: 614px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: auto;
  text-align: left;
}

.title {
  font-weight: bold;
  font-size: 36px;
  line-height: 1.116;
  color: var(--secondary-black-color);
  margin-bottom: 32px;
}

.formContainer {
  border: 1px solid var(--fifth-black-color);
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.formContainerTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  color: var(--secondary-black-color);
  margin-bottom: 2px;
}

.formTitleHelper {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  color: var(--third-black-color);
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  color: var(--secondary-black-color);
  // margin-bottom: 20px;
  // margin-top: 32px;
}

.input {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
  border-bottom: 1px solid var(--fourth-black-color);
  margin-top: 8px;
  padding: 8px;
}

.eyesButton {
  background-color: var(--primary-white-color);
  border: none;
  outline: none;
  width: 20px;
  position: absolute;
  right: 8px;
  bottom: 8px;
}

.submitButton {
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.0016em;
  color: var(--primary-white-color);
  background: var(--third-red-color);
}

.forgotButton {
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 4px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.0016em;
  background: var(--primary-white-color);
  color: var(--secondary-red-color);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.authWithTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.444;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
  margin-bottom: 16px;
}

.errorMsg {
  color: var(--primary-red-color);
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
}

.cashWrapper {
  display: flex;
  flex-direction: column;
  padding: 12px 16px 12px 24px;
  max-width: 500px;
  height: 68px;
  border: 2px solid var(--third-blue-color);
  border-radius: 4px;
  margin-bottom: 32px;
}

.cashWrapper div {
  display: flex;
  text-align: center;
}

.cashText {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--third-black-color);
  margin-right: 8px;
}

.cashButton {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.285;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0012em;
  color: var(--secondary-black-color);
  background-color: var(--primary-white-color);
  border: none;
  outline: none;
}
.authorizationBtnWrap {
  // padding: 0 24px;
  @media screen and (max-width: breakpoints.$small) {
    padding: 0 24px;
  }
}

@media screen and (max-width: breakpoints.$small) {
  .header {
    padding: 16px;
    align-items: center;
    margin-bottom: 48px;
  }
  .title {
    text-align: center;
    padding: 0 24px;
  }
  .cashWrapper {
    margin: 0 24px 32px;
    height: auto;
  }
  .formContainer {
    padding: 32px 24px;
  }
  .label {
    margin-bottom: 0;
  }
}
@media screen and (max-width: breakpoints.$extraSmall) {
  .formContainer {
    border-radius: 0;
    border: none;
    border-top: 1px solid var(--fifth-black-color);
    margin: 0;
  }
  .altLoginWrap {
    padding: 0 24px 24px;
  }
}
