@use "./styles/breakpoints" as breakpoints;

.contractOfferText {
  font-family: var(--secondary-font-family);
  font-size: 14px;
  line-height: 1.555;
  color: var(--third-black-color);
  margin-top: 32px;
}

.contractOfferButton {
  border: none;
  background-color: transparent;
  font-family: var(--secondary-font-family);
  font-size: 14px;
  line-height: 1.555;
  color: var(--secondary-red-color);
}

.visibility {
  visibility: hidden;
}

.display {
  display: none;
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--primary-black-color);
  margin-bottom: 32px;
  width: 100%;
}

.select {
  width: 100%;
  padding: 8px 8px 8px 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  display: block;
  appearance: none;
}

.disabledOption {
  color: var(--fourth-black-color);
}

@media screen and (max-width: breakpoints.$extraSmall) {
  .contractOfferText {
    text-align: center;
    padding: 0 24px;
  }
}
