.modalBody {
  padding: 24px 0;
  width: 642px;
  height: max-content;
  background: var(--primary-white-color);
  box-shadow: 0 8px 12px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  position: absolute;
  top: 56px;
  right: 32px;
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.25;
  color: var(--secondary-black-color);
  margin-bottom: 24px;
  padding: 0 24px;
}

.wrapper {
  margin-bottom: 24px;
}

.wrapperTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.0016em;
  color: var(--third-black-color);
  margin-bottom: 10px;
  padding: 0 24px;
}

.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px;
  width: 100%;
  height: 58px;
  margin-bottom: 8px;
  cursor: pointer;
}

.card:hover {
  background-color: var(--sixth-black-color);
}

.cardText {
  height: 42px;
  margin-left: 12px;
}

.cardText h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
}

.cardText p {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--third-black-color);
}
