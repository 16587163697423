@use "../../styles/breakpoints" as breakpoints;

.pageWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.pageContainer {
  width: 100%;
  height: auto;
  position: relative;
  padding: 68px 32px 32px;
}

.pageTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.25;
  color: var(--secondary-black-color);
  margin-bottom: 48px;
}

.contentNav {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 51px;
  padding: 10px 12px 12px;
  border-bottom: 1px solid var(--fourth-black-color);
  margin-bottom: 48px;
  flex-wrap: wrap;
  @media (max-width: breakpoints.$small) {
    margin-bottom: 24px;
    padding: 0px;
  }
}

.contentTabLink {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.444;
  letter-spacing: 0.0016em;
  color: var(--third-black-color);
  padding-bottom: 8px;
  cursor: pointer;
  position: relative;
}

.contentTabLink.disabled {
  cursor: not-allowed;
}

.contentTabLink.active {
  font-weight: 600;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
}

.contentTabLink.active:after {
  content: "";
  position: absolute;
  left: 0;
  top: 42px;
  width: 100%;
  height: 2px;
  background-color: var(--secondary-black-color);
  @media (max-width: 768px) {
    top: 30px;
  }
}

.contentTabLink:not(:last-child) {
  margin-right: 24px;
}
