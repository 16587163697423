@use "../../../styles/breakpoints" as breakpoints;

.handleRepeatWrapper {
  align-self: center;
  position: relative;
}

.repeatButton {
  width: 165px;
  height: 46px;
  outline: none;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.repeatButton:disabled {
  display: block;
}

.repeatButton:disabled img {
  position: absolute;
  left: 0;
  top: 0;
}

.repeatButton:disabled span {
  position: absolute;
  left: 30px;
  top: 3px;
}

.repeatButton:disabled p {
  position: absolute;
  left: 30px;
}

@media screen and (max-width: breakpoints.$extraSmall) {
  .repeatButton {
    justify-content: flex-start;
  }
  .handleRepeatWrapper {
    margin-bottom: 0;
  }
}
