@use "../../../styles/breakpoints" as breakpoints;

.screenWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.pageContainer {
  width: 100%;
  height: auto;
  position: relative;
}

.mapboxgl-popup {
  top: -15px;
  min-width: 316px;
  max-width: none !important;
  box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
  cursor: pointer;
}

.mapboxgl-popup-tip {
  display: none;
}

.marker .classifier {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: var(--third-black-color);
  margin-bottom: 12px;
}

.marker .name {
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 2.8rem;
  color: var(--secondary-black-color);
}

.marker .address {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--third-black-color);
}

.marker .icon {
  width: 46px;
  height: 46px;
}

.marker .header {
  display: flex;
}

.header .text {
  max-width: 230px;
  margin-right: 24px;
}

.mapboxgl-popup-content {
  max-width: 450px;
  // padding: 12px 8px !important;
}

.mapboxgl-popup-content .popUpClassifier {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.4rem;
  // margin-top: 4px;
  color: var(--third-black-color);
}

.mapboxgl-popup-content .construction_type {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  // margin-top: 10px;
  color: var(--third-black-color);
}

.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-canvas {
  width: 100%;
  height: auto;
}

.markerActive {
  width: 20px;
  height: 20px;
}

.mapWrapper {
  width: 100%;
  margin-left: auto;
}

.mapWrapper.small {
  width: calc(100% - 560px);
  transition: width 0.2s ease-out;
}

@media screen and (max-width: breakpoints.$small) {
  .pageContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }
  .screenWrapper {
    flex-direction: column-reverse;
  }
  .smallerNav {
    display: none;
  }
  .filterBar {
    height: auto;
    width: 100%;
  }
  .mapDetailsContainer {
    position: relative;
    flex: 1;
  }
}
