@use "../../styles/breakpoints" as breakpoints;
@use "../../styles/zIndexes" as zIndexes;

.profileMenu {
  position: absolute;
  top: 32px;
  right: 0;
  z-index: 2;
  padding: 0 32px;
  width: 100%;
  @media screen and (max-width: 1215px) {
   z-index: 12;
  }
  @media screen and (max-width: breakpoints.$small) {
    z-index: zIndexes.$filters;
  }
}

.profileMenu > * {
  margin-bottom: 10px;
}
.profileMenu > *:last-child {
  margin-bottom: 0;
}

.menuBar {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smallerNav {
  width: calc(100% - 560px);
  margin-left: auto;
}

.profileMenuWrapper {
  display: flex;
  margin-right: auto;
}

.profileMenuWrapper button {
  margin-right: 24px;
}

.filterTriggerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 32px 0 24px;
  height: 48px;
  background: var(--primary-white-color);
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
  box-shadow: 0 8px 12px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.filterTriggerBtn:not(:first-child) {
  padding: 0 32px;
  margin: 0;
}

.filterTriggerBtn span {
  display: block;
}

.filterTriggerIcon {
  margin-right: 18px;
}

.smallBtn {
  min-width: 48px;
  padding-right: 0;
  padding-left: 16px;
}

.filterTriggerBtn .filterTriggerIcon {
  transform: rotate(180deg);
}

.smallBtn.filterTriggerBtn .filterTriggerIcon {
  transform: rotate(0deg);
}

.smallBtn span {
  display: none;
}

.userSettingsMenu {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.userMenuBtn {
  background-color: transparent;
  cursor: pointer;
  border: none;
}

.userMenuBtn:last-child {
  margin-right: 14px;
}

.userMenuBtn img {
  height: 20px;
}

.profileSettingsLogin {
  width: 48px;
  height: 48px;
  border: none;
  background: none;
  outline: none;
}

.profileSettingsLogin img {
  display: block;
  height: 100%;
  width: 100%;
}

.profileSettingsTrigger {
  overflow: hidden;
  position: relative;
  width: 48px;
  height: 48px;
  background-color: var(--primary-white-color);
  border-radius: 8px;
  cursor: pointer;
  border: none;
  filter: drop-shadow(0px 8px 12px rgba(9, 30, 66, 0.15))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.31));
}

.profileSettingsTrigger img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 48px;
  height: 48px;
  object-fit: cover;
  object-position: center;
}

/*Profile modal*/

.profileModal {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 56px;
  right: 32px;
  background-color: var(--primary-white-color);
  box-shadow: 0 8px 12px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  transform: scale(0.5);
  transition: 0.4s all;
}

.profileModal.active {
  transform: scale(1);
}

.profileModalHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 8px 16px 16px;
  width: 260px;
  height: 72px;
  background: var(--sixth-black-color);
  border-bottom: 1px solid var(--fifth-black-color);
}

.profileModalHeader img:first-child {
  margin-right: 12px;
}

.profileModalHeader img:not(:first-child) {
  margin-left: auto;
}

.textWrapper {
  display: flex;
  flex-direction: column;
}

.accountName {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--secondary-black-color);
}

.membersCount {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  color: var(--third-black-color);
}

.logoutWrapper {
  background-color: #fff;
  padding: 12px 16px;
  width: 260px;
  height: 48px;
}

.profileLinks {
  display: grid;
  border-bottom: 1px solid var(--fifth-black-color);
}

.profileLinkWrapper {
  background-color: #fff;
  padding: 12px 16px;
  width: 260px;
  height: 48px;
}

.logout {
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--secondary-red-color);
}

.accountsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 260px;
  max-height: 260px;
  overflow: auto;
  background: var(--primary-white-color);
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 268px;
}

.accountsWrapper .profileModalHeader {
  background: var(--primary-white-color);
  cursor: pointer;
}

/*Filters and templates modal*/

.modalHeader {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e6e6e6;
  padding: 16px 24px 0;
  width: 100%;
}

.modalHeader img {
  margin-left: auto;
  padding-bottom: 13px;
  cursor: pointer;
}

.contentTabLink {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.444;
  letter-spacing: 0.002em;
  color: var(--third-black-color);
  padding-bottom: 13px;
  cursor: pointer;
}

.active.contentTabLink {
  font-weight: 600;
  color: var(--secondary-black-color);
  border-bottom: 4px solid var(--secondary-black-color);
}

.contentTabLink:not(:last-child) {
  margin-right: 24px;
}

.modalAttention {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
  width: 100%;
  height: 64px;
  background-color: #f0f6ff;
}

.modalAttention img {
  margin-right: 12px;
}

.modalAttention p {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--secondary-black-color);
}

.width {
  width: 100%;
}

.result {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.444;
  letter-spacing: 0.002em;
  color: var(--third-black-color);
}

.result span {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.555;
  color: var(--secondary-black-color);
  margin-left: 5px;
}

.profileModalHeader.active {
  background: var(--sixth-black-color);
}

@media screen and (max-width: breakpoints.$medium) {
  .smallerNav {
    width: calc(100% - 360px);
  }
}

@media screen and (max-width: breakpoints.$small) {
  .smallerNav {
    display: none;
  }
  .profileModal {
    position: static;
    transform: none;
    box-shadow: none;
    border-radius: 0;
  }
  .profileModalHeader {
    width: 100%;
    border-bottom: none;
  }
  .profileModalHeader:first-child {
    border-top: 1px solid var(--fifth-black-color);
  }
  .profileModalHeader img:not(:first-child) {
    display: none;
  }
  .accountsWrapper {
    position: static;
    width: 100%;
    margin-bottom: 12px;
  }
  .profileLinks {
    border-top: 1px solid var(--fifth-black-color);
    border-bottom: none;
  }
}
