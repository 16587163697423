.mainWrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.regContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 500px;
  max-height: 828px;
  height: max-content;
  margin: auto;
}

.regContainer h1 {
  font-weight: bold;
  font-size: 36px;
  line-height: 1.166;
  color: var(--secondary-black-color);
  margin-bottom: 8px;
}

.regContainerText {
  font-size: 14px;
  line-height: 1.571;
  color: var(--third-black-color);
  margin-bottom: 35px;
}

.specialInfo {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.specialInfo p {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--third-black-color);
  margin-left: 12px;
}

.form {
  width: 100%;
}

.formWrapper {
  max-width: 500px;
  min-height: 81px;
  margin: auto;
  border: 1px solid var(--fifth-black-color);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.input {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.333;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
  border-bottom: 1px solid var(--fourth-black-color);
  margin-top: 16px;
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  color: var(--secondary-black-color);
  margin-bottom: 20px;
  margin-top: 32px;
}

.eyesButton {
  background-color: var(--primary-white-color);
  border: none;
  outline: none;
  width: 20px;
  position: absolute;
  right: 0;
  bottom: 2px;
}

.errorMsg {
  color: var(--primary-red-color);
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
}

.thirdFormSubmitButton {
  width: 218px;
  height: 48px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  background-color: var(--primary-black-color);
  border: 1px solid var(--secondary-black-color);
  color: var(--primary-white-color);
  margin-top: 32px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
