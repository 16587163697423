@use "../../../styles/breakpoints" as breakpoints;

.regContainer {
  max-width: 500px;
  min-height: 778px;
  margin: auto;
}

.regContainerTitle {
  font-weight: bold;
  font-size: 36px;
  line-height: 1.166;
  color: var(--secondary-black-color);
  margin-bottom: 8px;
}

.regContainerText {
  font-size: 14px;
  line-height: 1.571;
  color: var(--third-black-color);
  margin-bottom: 35px;
}

.form {
  margin-top: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.formWrapper {
  max-width: 500px;
  min-height: 81px;
  margin: auto;
  border: 1px solid var(--fifth-black-color);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.formTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
  color: var(--secondary-black-color);
}

.input {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.333;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
  border-bottom: 1px solid var(--fourth-black-color);
  margin-top: 16px;
}

.inputWidth {
  width: 85%;
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  color: var(--secondary-black-color);
  margin-bottom: 20px;
  margin-top: 32px;
}

.select {
  width: 100%;
  padding: 8px 8px 8px 10px;
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid var(--fourth-black-color);
  display: block;
  appearance: none;
}

.eyesButton {
  background-color: var(--primary-white-color);
  border: none;
  outline: none;
  width: 20px;
  position: absolute;
  right: 0;
  bottom: 2px;
}

.formSubmitButton {
  width: 199px;
  height: 48px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.15;
  letter-spacing: 0.0016em;
  background-color: var(--primary-white-color);
  border: 1px solid var(--secondary-black-color);
  color: var(--secondary-black-color);
  margin-top: 32px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.editButtonWrapper {
  max-width: 500px;
  min-height: 80px;
  border: 1px solid var(--fifth-black-color);
  border-radius: 8px;
  background-color: var(--primary-white-color);
  position: relative;
  padding: 16px;
}

.editButtonContainer {
  display: flex;
  flex-direction: column;
}

.editButton {
  background-color: var(--primary-white-color);
  border: none;
  outline: none;
  position: absolute;
  right: 5px;
  top: 5px;
}
.editButton > img {
  display: block;
}

.editValidationText {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--third-black-color);
  margin-top: 5px;
}

.formsWrapperDF {
  display: flex;
}

.formsWrapperDF form {
  width: 100%;
}

.infoContainer {
  max-width: 500px;
  min-height: 60px;
  border: 2px solid var(--third-blue-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 12px 12px 12px 24px;
  margin-bottom: 32px;
}

.infoContainerText {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--third-black-color);
  margin-left: 5px;
}

.specialInfo {
  display: flex;
  align-items: center;
}

.specialInfo img {
  margin-right: 12px;
}

.specialInfo p {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--third-black-color);
}

.closeButtonSvg {
  background-color: var(--primary-white-color);
  border: none;
}

.thirdFormWrapper {
  max-width: 500px;
  min-height: 81px;
  margin: auto;
  border: 1px solid var(--fifth-black-color);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.thirdForm {
  margin-top: 32px;
}

.thirdFormSubmitButton {
  width: 218px;
  height: 48px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  background-color: var(--primary-black-color);
  border: 1px solid var(--secondary-black-color);
  color: var(--primary-white-color);
  margin-top: 32px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.errorMsg {
  color: var(--primary-red-color);
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
}

:global .ant-form-item-row .ant-form-item-row {
  display: block;
}

.secondFormInnerWrapper {
  display: flex;
}

.secondFormInputWrapper {
  flex: 1;
}

@media screen and (max-width: breakpoints.$medium) {
  .regContainerTitle,
  .regContainerText {
    text-align: center;
  }
  .specialInfo {
    justify-content: center;
  }
  .regContainer {
    min-height: auto;
  }
  .thirdForm {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: breakpoints.$extraSmall) {
  .thirdFormWrapper,
  .formWrapper {
    border-radius: 0;
    border: none;
    border-top: 1px solid var(--fifth-black-color);
    padding: 32px 24px;
  }
  .regContainerTitle {
    padding: 0 16px;
    margin-bottom: 16px;
  }
  .regContainerText {
    padding: 0 16px;
  }
  .formSubmitButton {
    margin: 16px auto;
  }
  .form {
    margin-top: 32px;
    padding-top: 0;
  }
  .label {
    margin-bottom: 0;
  }
  .label > div {
    margin-bottom: 0;
  }
  .editButtonWrapper {
    border: none;
    border-radius: 0;
    background: var(--dark-d-75, #f5f5f7);
    padding: 16px 24px;
  }
  .editButton {
    background: none;
    top: 16px;
    right: 24px;
  }
  .infoContainer {
    margin: 0 24px 32px;
  }
  .formsWrapperDF {
    padding: 0 24px;
  }
  .inputWidth {
    width: auto;
  }
  .input {
    height: 44px;
    line-height: 1;
    margin: 0;
  }
  .thirdFormSubmitButton {
    margin: 0 auto;
  }
  .secondFormInnerWrapper {
    display: block;
  }
}
