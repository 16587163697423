@use "../../styles/breakpoints" as breakpoints;

.mainWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.backgroundWrapper {
  background: #fafafa;
  padding: 32px;
  width: 100%;
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--primary-white-color);
  border: 1px solid var(--fifth-black-color);
  border-radius: 8px;
  height: 100%;
}

.pageWrapper img {
  margin-bottom: 48px;
}

.errorTitle {
  font-weight: 600;
  font-size: 36px;
  line-height: 1.166;
  text-align: center;
  color: var(--secondary-black-color);
  margin-bottom: 8px;
}

.errorText {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: var(--secondary-black-color);
  margin-bottom: 24px;
}

.errorLink {
  padding: 4px 24px;
  width: 133px;
  min-height: 40px;
  background: var(--secondary-black-color);
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--primary-white-color);
  text-decoration: none;
  display: flex;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: breakpoints.$small) {
  .mainWrapper {
    flex-direction: column-reverse;
  }
  .backgroundWrapper {
    height: 100%;
    padding: 0;
    border: none;
    border-radius: 0;
  }
  .pageWrapper {
    border-radius: 0;
    padding: 16px;
  }
  .errorLink {
    width: 100%;
    justify-content: center;
  }
}
