.overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(38, 45, 51, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  z-index: 10000;
}

.overlay.active {
  opacity: 1;
  pointer-events: all;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 550px;
  min-height: 304px;
  border-radius: 4px;
  background-color: var(--primary-white-color);
  transform: scale(0.5);
  transition: 0.4s all;
}

.modal.active {
  transform: scale(1);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 56px;
  padding: 16px 24px;
}

.modalHeader img {
  cursor: pointer;
}

.modalTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.333;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalTitle img {
  margin-right: 10px;
  margin-bottom: 4px;
}
