.overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(38, 45, 51, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
}

.overlay.active {
  opacity: 1;
  pointer-events: all;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--primary-white-color);
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  transform: scale(0.5);
  transition: 0.4s all;
  max-height: 95%;
}

.modal.active {
  transform: scale(1);
}
