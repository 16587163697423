@use "./../../styles/breakpoints" as breakpoints;

.contentTabLink {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--third-black-color);
  padding-bottom: 8px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  animation: text-shadow 1s both, border-bottom 1s both;
  &:hover {
    color: var(--secondary-black-color);
    border-bottom: 2px solid var(--secondary-black-color);
  }
}

.active.contentTabLink {
  color: var(--secondary-black-color);
  text-shadow: 0 0 0.25px var(--secondary-black-color);
  border-bottom: 2px solid var(--secondary-black-color);
}

@media screen and (max-width: breakpoints.$small) {
  .contentTabLink {
    overflow: auto;
  }
}
