@use "../../../../styles/breakpoints" as breakpoints;

.filterMainWrapper {
  padding: 32px 24px 16px;
  border-bottom: 1px solid var(--fourth-black-color);
}

.regionsDatasetsWrapper {
  display: flex;
  gap: 16px;
  @media (max-width: breakpoints.$small) {
    align-items: center;
  }
}
.topContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.personsDatasetFilters .topContainer {
  margin-bottom: 16px !important;
}
.companyDatasetFilters .topContainer {
  margin-bottom: 16px !important;
}

.topContainerButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  gap: 16px;
  height: 40px;
}

.topContainerTitleButton {
  display: flex;
  align-items: center;
  text-align: center;
  width: auto;
  font-weight: 600;
  height: 40px;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  padding: 8px;
  background: var(--sixth-black-color);
  border: 1px solid var(--fifth-black-color);
  border-radius: 4px;
  cursor: pointer;
}

.topContainerTitleButton img {
  margin-right: 14px;
}

.topContainerPlusButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 40px;
  height: 40px;
  background: var(--sixth-black-color);
  border: 1px solid var(--fifth-black-color);
  border-radius: 4px;
  cursor: pointer;
}

.searchWrapper {
  width: 512px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
}

.filterGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 512px;
  height: 48px;
  margin-bottom: 24px;
  background: var(--primary-white-color);
  @media screen and (max-width: breakpoints.$medium) {
  }
}
.checkboxLabel {
  margin-bottom: 8px;
  cursor: pointer;
  @media screen and (max-width: breakpoints.$medium) {
    padding: 0px 16px;
    margin-bottom: 4px;
  }
}

@media screen and (max-width: breakpoints.$medium) {
  .searchWrapper {
    width: 100%;
    margin-bottom: 8px;
    padding: 0 16px;
  }

  .filterMainWrapper {
    padding: 16px 0 8px;
  }

  .topContainerButtons {
    max-width: 212px;
    width: auto;
    gap: 12px;
    height: auto;
  }
  .topContainerTitleButton {
    height: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 11px 16px 11px 8px;
    width: auto;
  }
  .topContainerPlusButton {
    height: 32px;
    width: 32px;
  }
  .filterGroup {
    margin-bottom: 0;
    gap: 12px;
    width: auto;
    height: auto;
    padding: 0 16px;
    justify-content: start;
    // flex-wrap: wrap;
  }
  .topContainer {
    margin-bottom: 24px;
    padding: 0 16px;
  }
}

@media screen and (max-width: breakpoints.$small) {
  .filterMainWrapper {
    padding: 16px 0 8px;
  }
  .filterGroup {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-top: 8px;
  }
  .topContainerTitleButton {
    padding: 4px 8px;
  }
  .topContainerTitleButton img {
    margin-right: 0;
  }
  .checkboxLabel {
    padding: 0 16px;
  }
}
