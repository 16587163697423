@use "../../../styles/breakpoints" as breakpoints;

.pageContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.25;
  color: var(--secondary-black-color);
}

.titleWrap {
  margin-bottom: 48px;
}

.mainWrapper {
  width: 100%;
  height: 100%;
  margin: auto;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  margin-bottom: 32px;
}

.search {
  width: 291px;
}

.searchLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--secondary-black-color);
}

.inputSearch {
  width: 100%;
  height: 44px;
  border-bottom: 1px solid var(--fifth-black-color);
}

.searchLabel img {
  background-color: var(--primary-white-color);
  border: none;
  outline: none;
  width: 25px;
  position: absolute;
  right: 3px;
  bottom: 6px;
}

.addUserButton {
  text-align: center;
  padding: 0 32px;
  width: 275px;
  height: 48px;
  background: var(--secondary-black-color);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--primary-white-color);
  border: transparent;
}

.disabledUserButton {
  text-align: center;
  padding: 0 32px;
  width: 275px;
  height: 48px;
  background: var(--fourth-black-color);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--primary-white-color);
  border: 1px solid var(--third-black-color);
  cursor: default;
}

.tableWrap {
  display: flex;
  gap: 24px;
}

.table {
  width: 100%;
  max-height: 502px;
  background: var(--primary-white-color);
  border: 1px solid var(--fifth-black-color);
  border-radius: 4px;
  border-spacing: 0;
  table-layout: auto;
  border-collapse: collapse;
  align-self: flex-start;
}

.tableHead {
  width: 100%;
}

.tableHeadRow {
  background: var(--sixth-black-color);
  height: 54px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.tableHeadRow th {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--third-black-color);
  min-width: max-content;
  width: auto;
  text-align: start;
  padding: 0 24px;
}

.tableBody {
  position: relative;
  max-height: 600px;
  overflow-y: auto;
}

.tableBodyRow {
  min-height: 72px;
  background: var(--primary-white-color);
  box-shadow: inset 0 -1px 1px #e1e3e6;
  border-bottom: 1px solid var(--sixth-black-color);
}

.tableBodyRow:hover,
.tableBodyRow.selected {
  background: var(--sixth-black-color);
}

.tableBodyRow td {
  padding: 0 24px;
  height: 72px;
  width: auto;
}

.tableHeadRow th:last-child,
.tableBodyRow td:last-child {
  width: 32px;
}

.tableBodyRow td:last-child {
  text-align: right;
}

.personCellWrap,
.headCellWrap {
  display: flex;
  align-items: center;
}

.tableBodyRow td:first-child {
  align-items: start;
}

.profileImgContainer {
  overflow: hidden;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  border: none;
  filter: drop-shadow(0px 8px 12px rgba(9, 30, 66, 0.15))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.31));
  margin-right: 12px;
}

.profileImgContainer img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  object-position: center;
}

.profileInfoContainer {
  display: flex;
  flex-direction: column;
}

.profileInfoContainer p {
  font-family: var(--secondary-font-family);
  font-size: 16px;
  line-height: 1.5;
  color: var(--secondary-black-color);
}

.profileInfoContainer span {
  font-family: var(--secondary-font-family);
  font-size: 13px;
  line-height: 1;
  color: var(--third-black-color);
  display: inline-block;
}

.admin {
  padding: 3px 8px;
  width: max-content;
  height: max-content;
  background: var(--fifth-black-color);
  border-radius: 4px;
  font-family: var(--secondary-font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.571;
  color: var(--third-black-color);
}

.lastActive {
  font-family: var(--secondary-font-family);
  font-size: 16px;
  line-height: 1.5;
  color: var(--third-black-color);
}

.status {
  font-family: var(--secondary-font-family);
  font-size: 16px;
  line-height: 1.5;
  color: var(--secondary-black-color);
}

.actions {
  cursor: default;
  font-family: var(--secondary-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--third-black-color);
}

.actionsButton {
  width: max-content;
  padding: 5px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--primary-white-color);
  border: 1px solid var(--third-black-color);
  background-color: var(--fourth-black-color);
  border-radius: 6px;
  cursor: default;
}

.menuButton {
  width: 32px !important;
  border-radius: 5px;
}

.menuButton img {
  cursor: pointer;
}

.menu {
  padding: 4px 0;
  position: fixed;
  width: 240px;
  height: max-content;
  top: 60px;
  right: 20px;
  background: var(--primary-white-color);
  box-shadow: 0 8px 12px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  z-index: 1;
}

.setAdminButton {
  padding: 8px 16px;
  width: 100%;
  min-height: 40px;
  font-family: var(--secondary-font-family);
  font-size: 16px;
  line-height: 1.5;
  color: var(--primary-black-color);
  border: none;
  background-color: transparent;
  text-align: start;
}

.setAdminButton:hover,
.deleteButton:hover {
  background-color: var(--sixth-black-color);
}

.deleteButton {
  padding: 8px 16px;
  width: 100%;
  height: 40px;
  font-family: var(--secondary-font-family);
  font-size: 16px;
  line-height: 1.5;
  color: var(--secondary-red-color);
  border: none;
  background-color: transparent;
  text-align: start;
  border-top: 1px solid var(--fourth-black-color);
}

.addedUserInfo {
  display: flex;
  align-items: flex-start;
  padding: 16px 24px;
  position: absolute;
  width: 100%;
  max-width: 480px;
  height: 90px;
  left: 132px;
  bottom: 56px;
  background: var(--primary-white-color);
  border: 1px solid var(--fifth-black-color);
  box-shadow: 0 8px 12px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
}

.textContainer {
  margin-left: 16px;
  margin-right: auto;
}

.textContainer h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.444;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
  margin-bottom: 8px;
}

.textContainer p {
  font-family: var(--secondary-font-family);
  font-size: 16px;
  line-height: 1.5;
  color: var(--secondary-black-color);
}

.usersCount {
  color: var(--dark-d-300, #575f6e);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.026px;
  margin-bottom: 8px;
}

.profileInfoWrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0px 8px;
}

@media screen and (max-width: breakpoints.$large) {
  .tableWrap {
    gap: 16px;
  }
  .tableBodyRow th {
    min-width: auto;
  }
  .tableBody {
    height: auto;
  }
  .table {
    overflow-y: hidden;
    height: 100%;
    flex: 1;
    min-height: 74px;
  }
  .mainWrapper {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: breakpoints.$medium) {
  .tableBodyRow td:first-child {
    width: auto;
  }
  .tableHeadRow th:first-child {
    width: auto;
  }
  .tableHeadRow {
    display: none;
  }
  .tableBodyRow td:not(:first-child, :last-child) {
    display: none;
  }
  .table {
    margin-bottom: 32px;
  }
  .tableBodyRow:hover {
    background: none;
  }
}

@media screen and (max-width: breakpoints.$small) {
  .tableBodyRow td {
    padding: 0;
  }
  .breadcrumbWrap {
    padding: 8px 16px;
  }
  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px; /* 125% */
    margin: 0;
  }
  .titleWrap {
    border-bottom: 1px solid var(--fifth-black-color);
    padding: 8px 16px;
    margin-bottom: 16px;
  }
  .pageContainer {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  .pageContainer > .nav {
    background-color: red;
  }
  .pageContainer .profileMenu {
    display: none;
  }
  .mainWrapper {
    padding: 0;
    margin-bottom: 32px;
    height: calc(100% - 92px);
  }
  .table {
    border: none;
    margin-bottom: 32px;
    height: auto;
    flex: 0 1 auto;
  }
  .tableBodyRow {
    border-bottom: 1px solid var(--fifth-black-color);
    box-shadow: none;
    padding: 0;
  }
  .tableBodyRow:last-of-type {
    border: none;
  }
  .profileImgContainer {
    height: 40px;
    width: 40px;
    filter: none;
  }
  .profileImgContainer img {
    height: 40px;
    width: 40px;
    display: block;
  }
  .tableHeadRow th:first-child,
  .tableBodyRow th:first-child {
    min-width: 0;
  }
  .addUserButton {
    width: auto;
    display: inline-block;
  }
  .addUserButton {
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.017px;
    padding: 0 24px;
    border-radius: 4px;
  }
  .addUserButtonWrap {
    margin-bottom: auto;
  }
  .titleWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .menuBtn {
    border: none;
    background: none;
    outline: none;
  }
}
