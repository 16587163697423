@use "../../styles/breakpoints" as breakpoints;

.header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 32px;
}

.closeButtonSvg {
  background-color: var(--primary-white-color);
  border: none;
}

.hidden {
  visibility: hidden;
}

@media screen and (max-width: breakpoints.$small) {
  .header {
    padding: 16px;
    align-items: center;
  }
}
