@use "../../styles/breakpoints" as breakpoints;

.mainWrapper {
  width: 500px;
  height: 12px;
  display: flex;
  flex-direction: row;
  margin-right: 60px;
}
.firstBall {
  width: 12px;
  height: 12px;
  background: var(--third-red-color);
  border: 1px solid transparent;
  border-radius: 50%;
  margin-right: 8px;
}
.secondBall {
  width: 12px;
  height: 12px;
  border: 1px solid var(--fourth-black-color);
  border-radius: 50%;
  margin-right: 8px;
}
.firstDash {
  width: 40px;
  border: 1px solid var(--fourth-black-color);
  align-self: center;
  margin-right: 8px;
}
.secondDash {
  width: 40px;
  border: 1px solid var(--secondary-red-color);
  align-self: center;
  margin-right: 8px;
}

@media screen and (max-width: breakpoints.$medium) {
  .mainWrapper {
    width: auto;
    margin: 48px auto;
    justify-content: center;
  }
}
