@use "../../../styles/breakpoints" as breakpoints;

.passRecContainer {
  max-width: 500px;
  margin: auto;
}

.passRecTitle {
  font-weight: bold;
  font-size: 36px;
  line-height: 1.166;
  color: var(--secondary-black-color);
  margin-bottom: 8px;
}

.passRecText {
  font-size: 14px;
  line-height: 1.571;
  color: var(--third-black-color);
  margin-bottom: 32px;
}

.passRecForm {
  margin-top: 24px;
  padding-top: 24px;
  padding-bottom: 16px;
}

.passRecWrapper {
  max-width: 500px;
  min-height: 81px;
  margin: auto;
  border: 1px solid var(--fifth-black-color);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.passRecLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--secondary-black-color);
  margin-bottom: 16px;
}

.passRecInput {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.333;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
  border-bottom: 1px solid var(--fourth-black-color);
  margin-top: 16px;
}

.inputWidth {
  width: 85%;
}

.passRecButton {
  width: 187px;
  height: 48px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  border-radius: 4px;
  border: 1px solid var(--secondary-black-color);
  background-color: var(--primary-white-color);
  color: var(--secondary-black-color);
  margin-top: 32px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.editContainer {
  max-width: 500px;
  min-height: 80px;
  border: 1px solid var(--fifth-black-color);
  border-radius: 8px;
  background-color: var(--primary-white-color);
  position: relative;
  padding: 16px;
  margin-bottom: 24px;
}

.activeEditContainer {
  max-width: 500px;
  height: 212px;
  border: 1px solid var(--fifth-black-color);
  border-radius: 8px;
  background-color: var(--primary-white-color);
  padding: 24px;
  margin-bottom: 24px;
  position: relative;
}

.activeEditButton {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 24px;
  min-width: 125px;
  height: 40px;
  background: var(--secondary-black-color);
  border: 1px solid transparent;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--primary-white-color);
  gap: 12px;
}

.activeEditButtonPosition {
  position: absolute;
  bottom: 24px;
  right: 24px;
}

.editEmail {
  display: flex;
  flex-direction: column;
}

.editText {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--third-black-color);
  margin-top: 5px;
}

.editButton {
  background-color: var(--primary-white-color);
  border: none;
  outline: none;
  position: absolute;
  right: 5px;
  top: 5px;
}

.PassRecSecondPageWrapper {
  display: flex;
}

.PassRecSecondPageWrapper form {
  width: 100%;
}

.PassRecSecondPageFormInnerWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.PassRecSecondPageContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.PassRecSecondPageContainer .PassRecLabel {
  margin-bottom: 16px;
}

.handleRepeatWrapper {
  align-self: center;
  position: relative;
}

.repeatButton {
  width: 165px;
  height: 46px;
  outline: none;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.repeatButton:disabled {
  display: block;
}

.repeatButton:disabled img {
  position: absolute;
  left: 0;
  top: 0;
}

.repeatButton:disabled span {
  position: absolute;
  left: 30px;
  top: 3px;
}

.form {
  margin-top: 24px;
  padding-top: 24px;
}

.formWrapper {
  max-width: 500px;
  min-height: 81px;
  border: 1px solid var(--fifth-black-color);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  margin: auto auto 32px;
}

.eyesButton {
  background-color: var(--primary-white-color);
  border: none;
  outline: none;
  width: 20px;
  position: absolute;
  right: 0;
  bottom: 2px;
}

.errorMsg {
  color: var(--primary-red-color);
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
}

@media screen and (max-width: breakpoints.$medium) {
  .passRecTitle {
    text-align: center;
    padding: 0 24px;
    margin-bottom: 16px;
  }
  .passRecText {
    text-align: center;
    padding: 0 24px;
  }
}
@media screen and (max-width: breakpoints.$extraSmall) {
  .activeEditContainer {
    border-radius: 0;
    margin-top: 16px;
    border: none;
    border-top: 1px solid var(--fifth-black-color);
  }
  .activeEditContainer .activeEditButton {
    position: static;
  }
  .passRecButton {
    margin: 32px auto;
  }
  .passRecForm {
    margin-top: 0;
    padding-top: 0;
  }
  .passRecWrapper {
    border-radius: 0;
    border: none;
    border-top: 1px solid var(--fifth-black-color);
    padding: 32px 24px 0;
  }
  .passRecText {
    margin-bottom: 32px;
  }
  .passRecLabel {
    margin-bottom: 0;
    margin-top: 32px;
  }
  .passRecLabel:first-of-type {
    margin-top: 0;
  }
  .editContainer {
    border-radius: 0;
    border: none;
    background: var(--dark-d-75, #f5f5f7);
    padding: 16px 24px;
    margin: 32px 0;
  }
  .editButton {
    background: none;
    top: 16px;
    right: 24px;
  }
  .PassRecSecondPageWrapper {
    padding: 0 24px;
  }
  .PassRecSecondPageContainer .PassRecLabel {
    margin-bottom: 0;
  }
  .inputWidth {
    width: auto;
  }
  .form {
    padding-top: 0;
    margin-top: 32px;
    padding-bottom: 16px;
  }
  .formWrapper {
    border-radius: 0;
    border: none;
    border-top: 1px solid var(--fifth-black-color);
    padding: 32px 24px 0;
  }
  .passRecInput {
    margin-top: 8px;
    line-height: 2.225;
  }
  .activeEditButton {
    margin: 32px auto;
    height: 48px;
  }
  .eyesButton {
    bottom: auto;
    top: 50%;
    transform: translate(0, -50%);
  }
}
