@use "../../../../styles/breakpoints" as breakpoints;

.DownloadResults {
  width: 100%;
  background-color: var(--primary-white-color);
  padding: 26px 24px 32px;
  border-top: 1px solid var(--fourth-black-color);
  display: grid;
  gap: 24px;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 24px;
}

.label {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.444;
  letter-spacing: 0.002em;
  color: var(--third-black-color);
}

.label span {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.555;
  color: var(--secondary-black-color);
}

.currentPage {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.555;
  color: var(--primary-black-color);
  margin-right: 16px;
}

.pagesNum {
  display: flex;
  align-items: center;
}

.arrowBtn {
  background: transparent;
  border: none;
  outline: none;
}

.downloadBtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--primary-white-color);
  background: var(--third-red-color);
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: scale 0.3s ease-out;
  // onclick opacitty
  &:active{
    opacity: 0.9;
  }
}

.downloadBtn.disable {
  color: var(--primary-white-color);
  background: var(--fourth-black-color);
}

.downloadBtn.disable span {
  margin-right: 15px;
}

.downloadIcon {
  margin-left: 12px;
}

.modalInfoWrapper {
  display: flex;
  flex-direction: row;
  padding: 12px 24px;
  height: 60px;
  background: #f0f6ff;
}

.modalInfoWrapper img {
  margin-right: 12px;
}

.infoText {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--secondary-black-color);
}

.bodyWrapper {
  padding: 24px;
  height: 108px;
  border-bottom: 1px solid var(--fifth-black-color);
}

.bodyText {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
}

.modalFooter {
  height: 80px;
  padding: 16px 24px;
  width: 100%;
}

.thirdModalFooter {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 17px 24px;
  gap: 16px;
}

.thirdModalFooter a {
  width: max-content;
  border: none;
}

.thirdModalFooter button {
  width: 167px;
}

.modalButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  width: 100%;
  height: 48px;
  border: 1px solid var(--secondary-black-color);
  color: var(--secondary-black-color);
  background-color: var(--primary-white-color);
  outline: none;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
}

.modalButton img {
  margin-left: 12px;
}

.modalAcceptWrapper {
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: 172px;
  border: 1px solid #e6e6e6;
}

.modalAcceptText {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
}

.modalAcceptText span {
  font-weight: 700;
}

@media screen and (max-width: breakpoints.$medium) {
  .DownloadResults {
    height: auto;
  }
  .pagination {
    display: block;
  }
}
