@use "../../../styles/breakpoints" as breakpoints;

.mainWrapper {
  padding: 32px;
  background-color: var(--primary-white-color);
  width: 100vw;
  height: 100vh;
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.25;
  color: var(--secondary-black-color);
  margin-bottom: 58px;
}

.table {
  width: 100%;
  height: 502px;
  background: var(--primary-white-color);
  border: 1px solid var(--fifth-black-color);
  border-radius: 4px;
  margin-bottom: 32px;
}

.tableHead {
  width: 100%;
}

.tableHeadRow {
  height: 54px;
  background: var(--sixth-black-color);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.tableHeadRow th {
  display: flex;
  justify-content: left;
  padding: 24px;
  align-items: center;
  min-width: 140px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--third-black-color);
}

.tableBody {
  width: 100%;
  position: relative;
  max-height: 600px;
  overflow: auto;
}

.tableBodyRow {
  height: 72px;
  background: var(--primary-white-color);
  box-shadow: inset 0 -1px 1px #e1e3e6;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid var(--sixth-black-color);
  cursor: pointer;
}

.tableBodyRow:hover {
  background: var(--sixth-black-color);
}

.tableBodyRow th {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 24px;
  min-width: 115px;
  width: 100%;
  font-family: var(--secondary-font-family);
  font-size: 16px;
  line-height: 1.5;
  color: var(--secondary-black-color);
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: var(--sixth-black-color);
  border: 1px solid var(--fourth-black-color);
  border-radius: 4px;
  margin-right: 12px;
}

.profileImgContainer {
  overflow: hidden;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  border: none;
  filter: drop-shadow(0px 8px 12px rgba(9, 30, 66, 0.15))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.31));
  margin-right: 12px;
}

.profileImgContainer img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  object-position: center;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-start;
}

.createAccountBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 24px 0 32px;
  width: 242px;
  height: 48px;
  background: var(--secondary-black-color);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--primary-white-color);
  margin-bottom: 10px;
}

.createAccountBtn img {
  margin-left: 5px;
}

.logoutButton {
  padding: 0 0;
  width: 118px;
  height: 48px;
  border: 1px solid var(--secondary-black-color);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
  background-color: transparent;
}

.accCellWrap {
  display: flex;
  align-items: center;
}
.membersCount {
  display: none;
}

@media screen and (max-width: breakpoints.$medium) {
  .tableHeadRow th:not(:last-child, :first-child),
  .tableBodyRow th:not(:last-child, :first-child) {
    display: none;
  }
}

@media screen and (max-width: breakpoints.$small) {
  .tableHeadRow {
    display: none;
  }
  .tableBodyRow th:last-child {
    display: none;
  }
  .tableBodyRow th {
    padding: 0;
  }
  .mainWrapper {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
  }
  .table {
    border: none;
    height: auto;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  .tableBodyRow {
    border: none;
    box-shadow: none;
    min-height: 48px;
    height: auto;
  }
  .tableBody {
    max-height: 100%;
  }
  .title {
    margin: 0;
    padding: 8px 0;
  }
  .imgContainer {
    height: 32px;
    width: 32px;
  }
  .buttonsWrapper {
    margin-bottom: 16px;
  }
  .logoutButton {
    width: auto;
    padding: 8px 16px;
    height: auto;
  }
  .membersCount {
    display: block;
    color: var(--dark-d-300, #575f6e);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
  .accDataWrap {
    text-align: left;
  }
  //noinspection ALL
  .accName {
    color: var(--dark-d-400, #242731);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.017px;
  }
}
