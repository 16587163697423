.modalBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 100%;
  max-height: 620px;
  overflow: auto;
  background: var(--primary-white-color);
}

.filterWrapper {
  width: 608px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--fifth-black-color);
  padding-bottom: 23px;
}

.filterWrapper:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.titleWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 26px;
  margin-bottom: 24px;
}

.titleWrapper p {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.0022em;
  color: var(--secondary-black-color);
  margin-right: 13px;
}

.optionsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
}

.typeCard {
  width: 292px;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  margin-bottom: 1px;
  border-radius: 6px;
  cursor: pointer;
}

.typeCard:hover {
  background-color: var(--sixth-black-color);
}

.active {
  background: var(--fifth-black-color);
}

.active img {
  border: 1px solid var(--secondary-red-color);
}

.typeCard:hover img {
  background: var(--primary-white-color);
}

.optionName {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 1.571;
  color: var(--secondary-black-color);
}

.showMoreWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 24px;
  margin-bottom: 24px;
}

.showMoreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
}

.showMoreButton span {
  margin-left: 8px;
}

.showMoreButton img {
  margin-left: 8px;
}

.filterByCcWrapper {
  /*width: 608px;*/
}

.ccWrapper {
}
