@use "../../../../styles/breakpoints" as breakpoints;

.mapWrapper {
  width: 100%;
  height: 100%;
  margin-left: auto;
}

.mapWrapper.small {
  width: calc(100% - 560px);
  transition: width 0.2s ease-out;
}

.titleStyle {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--secondary-black-color);
  margin-top: 13px;
}

.popUpClassifier {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  color: var(--third-black-color);
}

.construction_type {
  font-family: Roboto, sans-serif;
  font-size: 13px;
  line-height: 1.307;
  color: var(--third-black-color);
}

.navControl {
  position: fixed;
  bottom: 32px;
  right: 32px;
}

.navControl button {
  width: 35px;
  height: 35px;
}
@media screen and (max-width: breakpoints.$medium) {
  .mapWrapper.small {
    width: calc(100% - 360px);
    transition: width 0.2s ease-out;
  }
}

@media screen and (max-width: breakpoints.$small) {
  .mapWrapper.small {
    width: 100%;
  }
}
