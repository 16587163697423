.subMainWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 1188px;

  height: 100%;
  margin: auto;
  align-items: center;
  @media screen and (max-width: 1190px) {
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    height: auto;
    margin-bottom: 12px;
  }
}

.header {
  height: 72px;
  margin-bottom: 32px;
  @media screen and (max-width: 1190px){
    margin-top: 12px;
    
  }
}

.header h1 {
  font-weight: bold;
  font-size: 36px;
  line-height: 1.166;
  color: var(--secondary-black-color);
  margin-bottom: 8px;
}

.header p {
  font-family: var(--secondary-font-family);
  font-size: 14px;
  line-height: 1.571;
  color: var(--third-black-color);
}

.bodyWrapper {
  display: flex;
  flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    @media  (max-width: 1190px) {
      flex-direction: column;
    }
    @media  (max-width: 768px)  {
      margin: 0 4px;
    }
}

.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 600px;
  min-height: 302px;
  background: var(--primary-white-color);
  border: 1px solid var(--fifth-black-color);
  border-radius: 8px;
  margin-right: 108px;

  @media (max-width: 1190px) {
    margin-right: 0;
    // margin-bottom: 32px;
    width: auto;
  }
  @media  (max-width: 768px){
    margin: 0;
    width: auto;
    max-width: 600px;
  }
}

.formTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.0022em;
  color: var(--secondary-black-color);
  margin-bottom: 32px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid var(--fifth-black-color);
}

.formDescry {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
  margin-bottom: 16px;
}

.radioContainer {
  display: flex;
  margin-bottom: 32px;
  @media  (max-width: 768px)  {
      flex-direction: column;
      gap: 4px;
      margin-bottom: 16px;
  }
}

.radioLabel {
  display: flex;
  font-family: var(--secondary-font-family);
  font-size: 16px;
  line-height: 1.5;
  color: var(--secondary-black-color);
  align-items: flex-end;
  gap: 8px;
}

.radioLabel:not(:last-child) {
  margin-right: 27px;
}

.radioLabel span {
  color: var(--secondary-green-color);
  margin-left: 2px;
}

.priceWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  @media screen and (max-width: 768px){
  margin-bottom: 0px;
  }
}

.priceText {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.444;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
}

.priceCount {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.444;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
}

.showMoreWrapper {
  display: flex;
  flex-direction: row;
}

.showLessWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--fifth-black-color);
}

.showMoreButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
  @media screen and (max-width: 768px){
      margin-top: 16px;
  }
}

.showMoreButton img {
  margin-left: 8px;
}

.payContainer {
  width: 100%;
}

.payDescWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 24px;
  width: 100%;
  margin-bottom: 24px;
  @media screen and (max-width: 768px){
    margin-bottom: 16px;
  }
}

.payDescWrapper p {
  font-family: var(--secondary-font-family);
  font-size: 16px;
  line-height: 1.5;
  color: var(--secondary-black-color);
}

.payDescWrapper p span {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--fourth-black-color);
  margin-top: 4px;
}

.formSubmitButton {
  width: 185px;
  height: 44px;
  padding: 10px 16px 10px 24px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.15;
  letter-spacing: 0.0016em;
  background-color: var(--primary-white-color);
  border: 1px solid var(--third-black-color);
  color: var(--secondary-black-color);
  margin-top: 32px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media screen and (max-width: 768px){
    margin: 16px 0 0 0;
    width: 100%;
    justify-content: center;
  }

}
.formSection{
  @media  (max-width: 1190px) {
    order: 2;
  }
}

.subInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 480px;
  height: 206px;
  background: #fafafa;
  border-radius: 8px;
  @media  (max-width: 1190px) {
    order: 1;
    margin-bottom: 32px;
    width: auto;
  }
  @media  (max-width: 768px){
    width: auto;
    max-width: 480px;
    height: auto;
    margin-bottom: 16px;
  }
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.infoHeader {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.444;
  letter-spacing: 0.002em;
  color: var(--third-black-color);
}

.goBackButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: max-content;
  height: 40px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--secondary-black-color);
  color: var(--primary-red-color);

  background-color: transparent;
  border: none;
  @media (max-width: 768px){
    color: var(--primary-red-color)
  }
}

.infoContainer {
  width: 100%;
  height: 110px;
  background-color: var(--primary-white-color);
  padding: 16px;
  box-shadow: 0 1px 1px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  @media  (max-width: 768px){
    height: auto;
    padding: 20px 16px;
  }
}

.subName {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.444;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
  margin-bottom: 8px;
}

.subDecr {
  font-family: var(--secondary-font-family);
  font-size: 14px;
  line-height: 1.571;
  color: var(--third-black-color);
}

.errorMsg {
  margin-top: 10px;
  color: var(--primary-red-color);
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
}


.regionWrapper{
  @media screen and (max-width: 768px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.0016em;
    color: var(--secondary-black-color);
    margin-bottom: 16px;
    margin-bottom: -16px;
  }
}