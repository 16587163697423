@use "../../styles/breakpoints" as breakpoints;

.mainSubWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 100vh;
  padding: 32px;
}

.closeButtonSvg {
  position: absolute;
  right: 32px;
  top: 32px;
  border: none;
  background-color: transparent;
}

.subHeader {
  margin-bottom: 40px;
}

.subTitle {
  font-weight: bold;
  font-size: 36px;
  line-height: 1.166;
  color: var(--secondary-black-color);
  margin-bottom: 8px;
}

.subDescry {
  font-family: var(--secondary-font-family);
  font-size: 14px;
  line-height: 1.571;
  color: var(--third-black-color);
}

.subsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -16px;
  width: 100%;
  justify-content: center;
}

.subInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 32px;
  width: 100%;
  max-width: 320px;
  min-width: 50px;
  background: var(--primary-white-color);
  border: 1px solid var(--fifth-black-color);
  border-radius: 4px;
  margin: 16px;
}

.subInfoHeader {
  margin-bottom: 32px;
}

.subInfoHeaderLast {
  margin-bottom: 58px;
}

.subInfoTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.25;
  color: var(--secondary-black-color);
}

.subInfoDecry {
  font-family: var(--secondary-font-family);
  font-size: 14px;
  line-height: 1.571;
  color: var(--third-black-color);
}

.priceWrapper {
  margin-bottom: 32px;
}

.priceTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.444;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
}

.subStartingButton {
  text-align: center;
  padding: 0 24px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  border: 1px solid var(--secondary-black-color);
  color: var(--secondary-black-color);
  background-color: var(--primary-white-color);
  margin-bottom: 43px;
}

.subBenefitsList li {
  font-family: var(--secondary-font-family);
  font-size: 14px;
  line-height: 1.571;
  color: var(--secondary-black-color);
  list-style-type: none;
}

.subBenefitsList li:before {
  content: "✓";
  color: var(--secondary-black-color);
  margin-right: 8px;
}

.subProfButton {
  text-align: center;
  padding: 0 24px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  border: none;
  color: var(--primary-white-color);
  background: linear-gradient(90deg, #fb8261 0%, #f46a57 100%);
  margin-bottom: 32px;
}

.contactsLink {
  text-align: center;
  padding: 0 24px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: transparent;
  color: var(--secondary-red-color);
  font-weight: 600;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  margin-bottom: 32px;
  text-decoration: none;
}

.subBenefits {
  display: flex;
  align-items: center;
  font-family: var(--secondary-font-family);
  font-size: 14px;
  line-height: 1.571;
  color: var(--secondary-black-color);
  margin-bottom: 12px;
}

.subBenefits img {
  margin-right: 8px;
}

.subDisabledButton {
  text-align: center;
  padding: 0 24px;
  width: 264px;
  height: 40px;
  background: var(--sixth-black-color);
  border: 1px solid var(--fifth-black-color);
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--fourth-black-color);
  margin-bottom: 32px;
  cursor: default;
}

.mainSubContainer {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: breakpoints.$large) {
  .mainSubContainer {
    height: 100%;
    overflow: hidden;
  }
  .mainSubWrap {
    justify-content: flex-start;
    padding: 32px 24px;
  }
  .subHeader {
    margin: 40px 0;
  }
  .subsContainer {
    justify-content: center;
    /* overflow: auto; */
    flex: 1;
  }
  .subDescry,
  .subTitle {
    text-align: center;
  }
}

@media screen and (max-width: breakpoints.$small) {
  .closeButtonSvg {
    right: 3px;
  }
  .subTitle {
    margin-bottom: 16px;
  }
  .subHeader {
    margin: 32px 0;
  }
  .subBenefitsList li {
    margin-bottom: 12px;
  }
}
