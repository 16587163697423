.css-b62m3t-container {
  margin-top: 8px;
}

.css-1s2u09g-control:hover {
  background-color: var(--sixth-black-color);
}

.css-1s2u09g-control {
  border: none !important;
  border-bottom: 1px solid var(--fourth-black-color) !important;
  border-radius: 0 !important;
}
