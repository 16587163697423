.popup {
  margin: 0px;
  padding: 0px;
  .mapboxgl-popup-content {
    display: grid;
    gap: 12px;
    max-height: 400px;
    margin: 0px;
    padding: 0px;
    h3 {
      color: black;
    }
  }
}
