$header: 100;
$overlay: 200;

$filterPin: 1;
$filterBackdrop: 2;
$filters: 10;
$filtersModalMobile: 1006;

$modal: 1005;
$modalBackdrop: 101;

$searchBox: 1001;

$navbar: 1000;

$notification: 400;
$loader: 500;
